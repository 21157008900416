import { styled, useMediaQuery, useTheme } from '@mui/material';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { AGENT_PROFILE_URL } from '../../../constants/common';
import { MainContext } from '../../../context';
import routes from '../../../routes';
import './Header.scss';
import { CF_URL } from '../../../constants/common';

const ExploreCommunityContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

const FeaturedAgentPhoto = styled('img')({
  height: 80,
  width: 80,
  borderRadius: '100%',
  objectFit: 'cover',
  objectPosition: 'top',
});

const Header = () => {
  const location = useLocation();
  const [openCommunities, setOpenCommunities] = useState(false);
  const [toggleMenu, setToggleMenu] = useState('');
  const { isLoggedIn, subdomainAgent } = useContext(MainContext);

  const handleOpenModal = () => {
    if (!subdomainAgent?.communities_served?.length) {
      return;
    }

    setOpenCommunities(true);
  };

  useEffect(() => {
    setOpenCommunities(false);
  }, [location]);

  const ywhLogo = useMemo(() => {
    if (subdomainAgent) {
      if (subdomainAgent.brokerage.toLowerCase() === 'fathom') {
        return `${CF_URL}/web-assets/yourwayhome/logo/ywh-community-logo.webp`;
      } else {
        return `${CF_URL}/web-assets/yourwayhome/logo/ywh-community-agent-logo.webp`;
      }
    }
    return `${CF_URL}/web-assets/yourwayhome/logo/ywh-community-logo.webp`;
  }, [subdomainAgent]);

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <section>
        <div className="top-header">
          <nav className="top-header__wrapper">
            <img
              className="top-header__logo"
              style={{ maxWidth: 434, width: '100%' }}
              src={ywhLogo}
              alt="YourWayHome, LLC"
            />
            {!smScreen && <div className="top-header__nav-items">
              {subdomainAgent && <FeaturedAgentPhoto src={`${AGENT_PROFILE_URL}${subdomainAgent?.photo}`} alt="" />}
              <div className="flex-column top-header__contact">
                <span className="top-header__contact--company" style={subdomainAgent && { fontWeight: 700 }}>
                  {subdomainAgent ? subdomainAgent.first_name + ' ' + subdomainAgent.last_name : 'YourWayHome'}
                </span>
                <span className="top-header__contact--phone">
                  {subdomainAgent ? subdomainAgent.mobile_phone : '303-554-7652'}
                </span>
                <span className="top-header__contact--cta">
                  {subdomainAgent ? subdomainAgent.user.email : 'Call us today!'}
                </span>
              </div>
            </div> }
          </nav>
        </div>
        
        <div
          className="d-flex d-lg-none toggle-menu-container position-relative"
          style={{gap: '1rem', alignItems: 'center', cursor: 'pointer'}}
        >
            {!!subdomainAgent?.communities_served?.length && !toggleMenu && (
                    <button
                      onClick={handleOpenModal}
                      type="button"
                      className="btn-border"
                      style={{
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: '24px',
                        padding: '4px 32px',
                        borderRadius: 18,
                      }}
                    >
                      Explore Communities
                    </button>
                  )}
          {toggleMenu ? (
            <span  onClick={() => setToggleMenu('')} style={{cursor: 'pointer'}} className="toggle-menu-close">&times;</span>
          ) : (
            <span onClick={() => setToggleMenu('toggle-menu')}>
            <svg width="40" height="40" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M38.9231 2H5.07692C3.37759 2 2 3.37759 2 5.07692V38.9231C2 40.6224 3.37759 42 5.07692 42H38.9231C40.6224 42 42 40.6224 42 38.9231V5.07692C42 3.37759 40.6224 2 38.9231 2Z"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.3076 14.3078H29.6922M14.3076 22.0001H29.6922M14.3076 29.6924H29.6922"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            </span>
          )}
        </div>
        {!isLoggedIn && (
          <div className="d-flex bottom-header">
            <nav>
              <ul className={`ywh-navbar ${toggleMenu}`} style={{ padding: '20px 0' }}>
                <li>
                  <Link to={routes.app()} onClick={() => setToggleMenu('')}>
                    Home
                  </Link>
                </li>
                <li className="ywh-navbar__dropdown">
                  <a href="#buy" className="ywh-navbar__dropdown-btn">
                    Buy
                  </a>
                  <div className="ywh-navbar__dropdown-content">
                    <Link to={routes.chooseRealtor()} onClick={() => setToggleMenu('')}>
                      Choose Your Realtor
                    </Link>
                    <Link to={routes.makingOffer()} onClick={() => setToggleMenu('')}>
                      Making An Offer
                    </Link>
                    <Link to={routes.closingCost()} onClick={() => setToggleMenu('')}>
                      Closing Costs
                    </Link>
                  </div>
                </li>
                <li className="ywh-navbar__dropdown">
                  <a href="#sell" className="ywh-navbar__dropdown-btn">
                    Sell
                  </a>
                  <div className="ywh-navbar__dropdown-content">
                    <Link to={routes.pricingYourHome()} onClick={() => setToggleMenu('')}>
                      Pricing Your Home
                    </Link>
                    <Link to={routes.marketingYourHome()} onClick={() => setToggleMenu('')}>
                      Marketing Your Home
                    </Link>
                    <Link to={routes.showingYourHome()} onClick={() => setToggleMenu('')}>
                      Showing Your Home
                    </Link>
                    <Link to={routes.nineMinuteDrill()} onClick={() => setToggleMenu('')}>
                      Nine-Minute Drill
                    </Link>
                  </div>
                </li>
                <li>
                  <Link to={routes.customHomes()} onClick={() => setToggleMenu('')}>
                    Design+Build
                  </Link>
                </li>
                <li>
                  <a href="https://yourwayhome.net" target="_blank" rel="noreferrer">
                    Home Search
                  </a>
                </li>
                <li>
                  <a href="https://yourwayloan.com" target="_blank" rel="noreferrer">
                    Mortgage Site
                  </a>
                </li>
                {!subdomainAgent &&
                <li>
                  <a href="https://joinywh.net" target="_blank" rel="noreferrer">
                    Careers
                  </a>
                </li> }
                <li>
                  <Link to={routes.ourTeam()}>About</Link>
                </li>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                  {!!subdomainAgent?.communities_served?.length && (
                    <button
                      onClick={handleOpenModal}
                      type="button"
                      className="btn-border"
                      style={{
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: '24px',
                        padding: '4px 32px',
                        borderRadius: 18,
                      }}
                    >
                      Explore Communities
                    </button>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        )}
      </section>
      <Modal
        show={openCommunities}
        size="lg"
        centered
        onHide={() => {
          setOpenCommunities(false);
        }}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>Explore Communities</Modal.Header>
        <Modal.Body>
          <ExploreCommunityContainer>
            {subdomainAgent?.communities_served?.map((community) => (
              <Link key={community.id} to={`/community/${community.slug}/${community.id}`}>
                {community.name}
              </Link>
            ))}
          </ExploreCommunityContainer>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default memo(Header);
