import React, { useEffect, useRef, useState } from 'react';
import { getCommunityList } from '../../services/general';
import { createPopup } from '@typeform/embed';

const ywh_maps_url = `https://www.yourwayhome.net/api-site/search/suggestion?propertyId=0&siteId=26606`;
const ywh_listing_url = 'https://www.yourwayhome.net/listing?listingSource=all%20listings';
const ywh_street_url =
  'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?country=usa&beginHighlight=%253Cmark%253E&endHighlight=%253C%252Fmark%253E&maxresults=10&language=en&apikey=ossFwZuLtG6UBfU1nBYfnST0x6XhkGbLko7JKOhLYAA';
const ywh_evaluation_url = 'https://www.yourwayhome.net/evaluation?type=choose-suggestion';

const SearchBar = () => {
  const [term, setTerm] = useState('');
  const [tab, setTab] = useState('search-homes');
  const [type, setType] = useState('city');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [inputResult, setInputResult] = useState('');
  const [newHomesResult, setNewHomesResult] = useState('');
  const [fromResult, setFromResult] = useState(false);
  const [valuationResult, setValuationResult] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const timeout = useRef(null);

  const handleSearchTermChange = (event) => {
    setFromResult(false);
    setTerm(event.target.value);
  };

  const searchResaleClick = async (event, typeName = '', termName = '', cityName = '', stateName = '') => {
    event.preventDefault();
    let url =
      ywh_listing_url +
      '&keywordType=' +
      encodeURI(typeName || type) +
      '&key=' +
      encodeURI(termName || term) +
      '&condition=' +
      JSON.stringify({
        location: {
          [typeName || type]: [termName || term],
        },
      });
    if (tab === 'new-homes') {
      try {
        const fetchList = await getCommunityList({
          state: stateName || state,
          market: cityName || city,
        });

        if (state && city && fetchList.result && fetchList.communities.length > 0) {
          url = '/community/' + state + '/' + city;
          window.location.href = url;
        } else {
          setInputResult([]);
          setNewHomesResult(
            'It looks like you are wanting to search for new homes in ' +
              city +
              ', ' +
              state +
              ".  We can't find a matching new home community, but we're sending you all of the MLS results for that area including new and resale homes.",
          );
          setTimeout(() => {
            window.open(
              ywh_listing_url +
                '&key=' +
                (termName || term) +
                '&keywordType=' +
                type +
                '&minBuiltYear-maxBuiltYear=' +
                (new Date().getFullYear() - 20) +
                '%2C&page=1&zoom=12' +
                '&condition=' +
                JSON.stringify({
                  location: {
                    [typeName || type]: [termName || term],
                  },
                }),
            );
          }, 500);
          return;
        }
      } catch (e) {
        setInputResult([]);
        setNewHomesResult(
          'It looks like you are wanting to search for new homes in ' +
            city +
            ', ' +
            state +
            ".  We can't find a matching new home community, but we're sending you all of the MLS results for that area including new and resale homes.",
        );
        setTimeout(() => {
          window.open(
            ywh_listing_url +
              '&key=' +
              (termName || term) +
              '&keywordType=' +
              type +
              '&minBuiltYear-maxBuiltYear=' +
              (new Date().getFullYear() - 20) +
              '%2C&page=1&zoom=12' +
              '&condition=' +
              JSON.stringify({
                location: {
                  [typeName || type]: [termName || term],
                },
              }),
          );
        }, 500);
        return;
      }
    } else if (tab === 'search-homes') {
      window.open(url);
    }
  };

  const stripMarkTag = (str) => {
    if (str) return decodeURIComponent(str).replace(/(<([^>]+)>)/gi, '');
    return str;
  };

  const apiRequest = (term) => {
    setNewHomesResult('');
    if (term.length > 0) {
      if (tab === 'home-valuation') {
        fetch(ywh_street_url + '&query=' + term)
          .then((response) => response.json())
          .then((data) => {
            setValuationResult(data.suggestions);
          });
      } else {
        fetch(ywh_maps_url + '&key=' + term)
          .then((response) => response.json())
          .then((data) => {
            if (data.status && data.status.msg === 'success') {
              setInputResult(
                data.data.filter((d) =>
                  ['city', 'county', 'neighborhood', 'subdivision', 'community'].includes(d.type),
                ),
              );
            } else {
              setInputResult([]);
            }
          });
      }
    } else setInputResult([]);
  };

  useEffect(() => {
    //onload check hash

    if (window.location.hash) {
      setTab(window.location.hash.substring(1));
    }
  }, []);

  useEffect(() => {
    setInputResult([]);
    setTerm('');
    setValuationResult([]);
  }, [tab]);

  useEffect(() => {
    if (term && !fromResult) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        apiRequest(term);
      }, 300);
    } else {
      clearTimeout(timeout.current);
      setInputResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  return (
    <div className="search-bar">
      <div className="search-bar__tabs">
        <a
          className={tab === 'search-homes' ? 'active' : ''}
          href="#search-homes"
          onClick={() => setTab('search-homes')}
        >
          <span>Search For Homes</span>
        </a>
        <a href="#new-homes" className={tab === 'new-homes' ? 'active' : ''} onClick={() => setTab('new-homes')}>
          <span>New Homes</span>
        </a>
        <a
          href="#home-valuation"
          className={tab === 'home-valuation' ? 'active' : ''}
          onClick={() => setTab('home-valuation')}
        >
          <span>Home Valuation</span>
        </a>
        <a
          href="#mortgage-quote"
          className={tab === 'mortgage-quote' ? 'active' : ''}
          onClick={() => setTab('mortgage-quote')}
        >
          <span>Mortgage Quote</span>
        </a>
      </div>
      <div className="search-bar__search-field">
        {(tab === 'search-homes' || tab === 'new-homes' || tab === 'mortgage-quote') && (
          <div className="ywh-search-bar__tab-content">
            <form
              className="ywh-search-bar__form"
              onSubmit={(e) => {
                if (tab === 'new-homes') {
                  searchResaleClick(e, type, term, city, state);
                }
              }}
            >
              <div id="input-search-container" style={{ position: 'relative' }}>
                <input
                  name="search_term"
                  autoComplete="off"
                  type="text"
                  value={term}
                  className="search-bar__placeholder"
                  placeholder="City, County, Neighborhood, etc"
                  onChange={handleSearchTermChange}
                />

                {inputResult && (
                  <div id="input-typeahead-container">
                    <ul>
                      {inputResult.length > 0 &&
                        inputResult.map((result, key1) => {
                          let rType = result.type;
                          return (
                            result.list.length > 0 && (
                              <li key={key1} style={{ color: '#a8a8a8' }}>
                                {result.typeText}
                                {result.list.length > 0 ? (
                                  <ul>
                                    {result.list.map((r, key) => (
                                      <li
                                        key={key}
                                        onClick={(e) => {
                                          let splitArr = r.value.split(',').map((s) => s.trim());
                                          let splittedCity = splitArr[splitArr.length - 2];
                                          let splittedState = splitArr[splitArr.length - 1];
                                          setInputResult([]);
                                          if (tab === 'search-homes') {
                                            searchResaleClick(
                                              e,
                                              rType,
                                              r.value,
                                              splitArr[splitArr.length - 2],
                                              splitArr[splitArr.length - 1],
                                            );
                                          }
                                          setType(rType);
                                          setTerm(e.target.innerText);
                                          setCity(splittedCity);
                                          setState(splittedState);
                                        }}
                                      >
                                        {r.value}
                                      </li>
                                    ))}
                                  </ul>
                                ) : null}
                              </li>
                            )
                          );
                        })}

                      {newHomesResult && <li>{newHomesResult}</li>}
                    </ul>
                  </div>
                )}
              </div>
            </form>
            {tab === 'mortgage-quote' ? (
              <>
                <button
                  className="search-bar__button"
                  onClick={() => {
                    if (city && state) {
                      createPopup('VBh5Ecrd', {
                        hidden: {
                          utm_source: 'yourwayhome.com',
                          utm_medium: 'website-searchBar',
                          city,
                          state,
                          postalCode,
                        },
                        onEndingButtonClick: () => {
                          setValuationResult([]);
                        },
                      }).toggle();
                    } else alert('Please select city or state or zip first.');
                  }}
                >
                  <span>Continue</span>
                </button>
              </>
            ) : (
              <button className="search-bar__button" onClick={searchResaleClick}>
                {tab === 'search-homes' ? 'Search For Homes' : 'Search For New Homes'}
              </button>
            )}
          </div>
        )}

        {tab === 'home-valuation' && (
          <div className="ywh-search-bar__tab-content">
            <form className="ywh-search-bar__form" onSubmit={searchResaleClick}>
              <div id="input-search-container" style={{ position: 'relative' }}>
                <input
                  name="search_term"
                  autoComplete="off"
                  type="text"
                  value={term}
                  className="search-bar__placeholder"
                  placeholder={tab === 'home-valuation' ? 'Enter street address' : 'Enter City, ST or Zip'}
                  onChange={handleSearchTermChange}
                />
                {valuationResult && (
                  <div id="input-typeahead-container">
                    <ul className="vm-list">
                      {valuationResult.map((r, key) => {
                        const { houseNumber, street, city, state, postalCode } = r.address;
                        return (
                          <li
                            key={key}
                            onClick={(e) => {
                              if (tab === 'home-valuation') {
                                searchResaleClick(
                                  e,
                                  r.matchLevel,
                                  e.target.innerText,
                                  stripMarkTag(city),
                                  stripMarkTag(state),
                                );
                                setValuationResult([]);
                                setTerm(e.target.innerText);
                              } else {
                                setCity(stripMarkTag(city));
                                setState(stripMarkTag(state));
                                setPostalCode(stripMarkTag(postalCode));
                                setTerm(e.target.innerText);
                                setValuationResult([]);
                              }
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                tab === 'mortgage-quote'
                                  ? `${stripMarkTag(city)}, ${stripMarkTag(state)} ${stripMarkTag(postalCode)}`
                                  : `${houseNumber ? stripMarkTag(houseNumber) : ''} ${
                                      street ? stripMarkTag(street) + ',' : ''
                                    } ${stripMarkTag(city)}, ${stripMarkTag(state)} ${stripMarkTag(postalCode)}`,
                            }}
                          ></li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </form>

            <button
              className="search-bar__button"
              onClick={(e) => {
                e.preventDefault();
                if (term !== '') {
                  window.open(ywh_evaluation_url + '&address=' + term + '&inp=' + term);
                }
              }}
            >
              <span>Continue </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
